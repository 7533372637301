var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    [
      _c(
        "CCol",
        { attrs: { col: "12" } },
        [
          _c(
            "CCard",
            [
              _c(
                "CCardHeader",
                [
                  _c(
                    "CRow",
                    [
                      _c(
                        "CCol",
                        {
                          staticClass: "card-header-with-button",
                          attrs: { lg: "4" }
                        },
                        [
                          _c("CSelect", {
                            attrs: {
                              label: "Site",
                              placeholder: "-- Please Select --",
                              options: _vm.siteData,
                              value: _vm.selectedSite
                            },
                            on: {
                              "update:value": function($event) {
                                _vm.selectedSite = $event
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "CCol",
                        {
                          staticClass: "card-header-with-button",
                          attrs: { lg: "2" }
                        },
                        [
                          _c("CSelect", {
                            attrs: {
                              label: "Timeframe",
                              placeholder: "-- Please Select --",
                              options: [
                                { value: "This Month", label: "This Month" },
                                { value: "Last Month", label: "Last Month" },
                                { value: "This Week", label: "This Week" },
                                { value: "Last Week", label: "Last Week" },
                                { value: "This Year", label: "This Year" },
                                { value: "Last Year", label: "Last Year" }
                              ],
                              value: _vm.selectedTimeFrame
                            },
                            on: {
                              "update:value": function($event) {
                                _vm.selectedTimeFrame = $event
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "CCol",
                        {
                          staticClass: "card-header-with-button",
                          attrs: { lg: "2" }
                        },
                        [
                          _c("CInput", {
                            attrs: { label: "From", type: "date" },
                            model: {
                              value: _vm.dateFrom,
                              callback: function($$v) {
                                _vm.dateFrom = $$v
                              },
                              expression: "dateFrom"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "CCol",
                        {
                          staticClass: "card-header-with-button",
                          attrs: { lg: "2" }
                        },
                        [
                          _c("CInput", {
                            attrs: { label: "To", type: "date" },
                            model: {
                              value: _vm.dateTo,
                              callback: function($$v) {
                                _vm.dateTo = $$v
                              },
                              expression: "dateTo"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "CCol",
                        {
                          staticClass: "card-header-with-button",
                          staticStyle: { "padding-top": "33px" },
                          attrs: { lg: "2" }
                        },
                        [
                          _c(
                            "CButton",
                            {
                              attrs: { type: "submit", color: "primary" },
                              on: { click: _vm.onViewClick }
                            },
                            [_vm._v("View")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "CCardBody",
                [
                  _c(
                    "CRow",
                    [
                      _c(
                        "CCol",
                        { attrs: { lg: "6" } },
                        [
                          _c("h3", [_vm._v("Financials At A Glance")]),
                          _c("CDataTable", {
                            attrs: {
                              items: _vm.financialData,
                              striped: "",
                              header: false
                            }
                          })
                        ],
                        1
                      ),
                      _c("CCol", { attrs: { lg: "6" } })
                    ],
                    1
                  ),
                  _c(
                    "CRow",
                    { staticClass: "mt-4" },
                    [
                      _c("CCol", { attrs: { lg: "3" } }, [
                        _c("h3", [_vm._v("Detailed Transactions")])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "CRow",
                    [
                      _c(
                        "CCol",
                        { attrs: { lg: "12" } },
                        [
                          _c("CDataTable", {
                            attrs: {
                              tableFilter: "",
                              items: _vm.detailTransactionData,
                              striped: "",
                              "clickable-rows": "",
                              "items-per-page": 10,
                              pagination: {
                                doubleArrows: false,
                                align: "center"
                              }
                            },
                            on: { "row-clicked": _vm.rowClicked }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }